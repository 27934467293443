<template>
  <div class="templete-editor">
    <div>
      <a-menu
        mode="horizontal"
        :selectedKeys="selectPdfTabs"
        @select="selectPdfTabsEvent"
      >
        <a-menu-item key="default">
          <a-icon type="book" />普通模板
        </a-menu-item>
        <a-menu-item key="other">
          <a-icon type="experiment" />复杂模板
        </a-menu-item>
      </a-menu>
    </div>
    <div class="ee-w">
      <div class="e-l">
        <div class="btn-w">
          <a-button @click.stop="closeE"> <a-icon type="left" />返回</a-button>
        </div>
      </div>
      <div v-if="selectPdfTabs[0] == 'other'" class="e-c">
        <a-spin :spinning="spinning">
          <div class="e-w">
            <a-upload-dragger
              ref="uploadExcel"
              name="file"
              :multiple="false"
              :action="uploadUrl"
              accept=".pdf"
              :headers="uploadHeaders"
              :before-upload="beforeUpload"
              :showUploadList="showUploadList"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                请选择已经编辑好ACROFORM的合同上传，该功能请在开发人员的协助下使用！！！
              </p>
              <p class="ant-upload-hint">只支持.pdf文件格式上传。</p>
            </a-upload-dragger>
          </div>
          <a-row v-if="spTemplateInfo.fields.length > 0" class="e-w">
            <a-col :span="24" style="text-align: left"> 模板KEY值 </a-col>
            <a-col :span="24" style="text-align: left" class="e-col-chi">
              <a-tag
                color="blue"
                v-for="item in spTemplateInfo.fields"
                :key="item"
              >
                {{ item }}
              </a-tag>
            </a-col>
          </a-row>
          <a-row
            v-if="checkSuccessField.length > 0 || checkErrorField.length > 0"
            class="e-w"
          >
            <a-col :span="12" style="text-align: center"> 验证通过 </a-col>
            <a-col :span="12" style="text-align: center"> 验证不通过</a-col>
          </a-row>
          <a-row
            v-if="checkSuccessField.length > 0 || checkErrorField.length > 0"
            class="e-w"
          >
            <a-col :span="12" class="e-col-chi">
              <a-tag
                color="cyan"
                v-for="item in checkSuccessField"
                :key="item.key"
              >
                {{ item.item }}[{{ item.key }}]
              </a-tag>
            </a-col>
            <a-col :span="12" class="e-col-chi">
              <a-tag
                color="pink"
                v-for="item in checkErrorField"
                :key="item.key"
              >
                {{ item.item }}[{{ item.key }}]
              </a-tag>
            </a-col>
          </a-row>
        </a-spin>
      </div>
      <div v-if="selectPdfTabs[0] == 'default'" class="e-c">
        <a-spin :spinning="spinning">
          <div class="e-w">
            <div id="myEdit"></div>
            <div class="str-l">
              <!-- <span>当前已输入2568个字符, 您还可以输入77432个字符</span> -->
            </div>
          </div>
        </a-spin>
      </div>

      <div class="e-r">
        <a-form-model ref="ruleForm" :model="submitData" :rules="rules">
          <div class="s-n">
            <div class="t">设置模板名称</div>
            <div>
              <a-form-model-item ref="name" label="" prop="name">
                <a-input
                  size="small"
                  :disabled="formDisabled"
                  v-model="submitData.name"
                  placeholder="请输入模板名称"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur();
                    }
                  "
                />
              </a-form-model-item>
              <!-- <a-input
                allowClear
                placeholder="输入模板名称"
                v-model="this.submitData.name"
              /> -->
            </div>
          </div>

          <div class="s-n">
            <div class="t">描述信息</div>
            <div>
              <a-form-model-item ref="describe" label="" prop="describe">
                <a-input
                  :disabled="formDisabled"
                  type="textarea"
                  v-model="submitData.describe"
                  placeholder="请输入模板描述信息"
                  @blur="
                    () => {
                      $refs.describe.onFieldBlur();
                    }
                  "
                />
              </a-form-model-item>
            </div>
          </div>

          <div class="f-w">
            <div class="w">
              <div class="s-n">
                <div class="t">排序</div>
                <div>
                  <a-form-model-item
                    ref="sort"
                    label=""
                    prop="sort"
                    :wrapper-col="{ span: 12 }"
                  >
                    <a-input
                      size="small"
                      :disabled="formDisabled"
                      v-model="submitData.sort"
                      @blur="
                        () => {
                          $refs.sort.onFieldBlur();
                        }
                      "
                    />
                  </a-form-model-item>
                </div>
              </div>
            </div>

            <div class="w">
              <div class="s-n">
                <div class="t">是否启用</div>
                <div>
                  <a-form-model-item
                    label=""
                    prop=""
                    :wrapper-col="{ span: 12 }"
                  >
                    <a-switch v-model="switchVal" :loading="formDisabled" />
                  </a-form-model-item>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="s-n">
            <div class="t">排序</div>
            <div>
              <a-form-model-item
                ref="sort"
                label=""
                prop="sort"
                :wrapper-col="{ span: 12 }"
              >
                <a-input
                  size="small"
                  :disabled="formDisabled"
                  v-model="submitData.sort"
                  @blur="
                    () => {
                      $refs.sort.onFieldBlur();
                    }
                  "
                />
              </a-form-model-item>
            </div>
          </div> -->

          <!-- <div class="s-n">
            <div class="t">是否启用</div>
            <div>
              <a-form-model-item label="" prop="" :wrapper-col="{ span: 12 }">
                <a-switch v-model="switchVal" :loading="formDisabled" />
              </a-form-model-item>
            </div>
          </div> -->

          <div class="s-n">
            <div class="t">协议分类</div>
            <div>
              <a-form-model-item label="" prop="agreementCategoryId">
                <a-select
                  size="small"
                  v-model="categorySelectDesc"
                  @change="handleCategoryChange"
                  placeholder="协议分类"
                  :disabled="formDisabled"
                >
                  <a-select-option
                    :value="it.value"
                    v-for="(it, i) in categorySelectList"
                    :key="i"
                  >
                    {{ it.text }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </div>

          <div class="s-n">
            <div class="t">签署有效期</div>
            <div>
              <a-select
                size="small"
                style="min-width: 160px; padding: 6px 0"
                v-model="expriedSelectDesc"
                @change="handleExpriedChange"
                placeholder="签署有效期"
                :disabled="formDisabled"
              >
                <a-select-option
                  :value="it.value"
                  v-for="(it, i) in expriedSelectList"
                  :key="i"
                >
                  {{ it.desc }}
                </a-select-option>
              </a-select>
            </div>
            <div v-if="expriedSelectVal === -1" style="margin-top: 6px">
              <a-form-model-item
                ref="signEffective"
                label=""
                prop="signEffective"
              >
                <a-input
                  size="small"
                  placeholder="输入有效日期"
                  suffix="天"
                  v-model="submitData.signEffective"
                  @blur="signAtChange"
                />
              </a-form-model-item>
            </div>
          </div>
          <!-- v-model="agreementSelectDesc prop="agreementType"" -->
          <div class="s-n">
            <div class="t">协议类型</div>
            <div>
              <a-form-model-item label="">
                <a-select
                  size="small"
                  style="width: 100%"
                  v-model="agreementSelectDesc"
                  @change="handleAgreementTypeChange"
                  placeholder="请选择"
                  :disabled="formDisabled"
                >
                  <a-select-option :value="0">自然人</a-select-option>
                  <a-select-option :value="1">非自然人</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>

        <div class="s-v">
          <div class="t">设置模板变量</div>
          <div class="ds">
            快速添加员工姓名、部门、岗位等信息，内容中会自动替换成真实信息
          </div>
          <div v-if="!generalFieldsList.length && agreementSelectDesc">
            暂无数据
          </div>
          <div
            class="v-list"
            v-for="(item, index) in generalFieldsList"
            :key="index"
          >
            <div class="title">{{ item.groupName }}</div>
            <ul>
              <li v-for="(it, i) in item.children" :key="i">
                <span @click="insertHtml(it)">{{ it.showName }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="s-n" v-if="agreementSelectDesc !== null">
          <div class="t">使用表单变量</div>
          <div>
            <a-form-model-item label="" prop="" :wrapper-col="{ span: 12 }">
              <a-switch v-model="formSwitchVal" :loading="formDisabled" />
            </a-form-model-item>
          </div>
        </div>

        <div class="s-n" v-if="formSwitchVal">
          <div class="t">选择表单</div>
          <a-select
            size="small"
            style="min-width: 160px; padding: 6px 0"
            v-model="formSelectDesc"
            @change="handleFormChange"
            :disabled="formDisabled"
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in formSelectData"
              :key="i"
            >
              {{ it.text }}
            </a-select-option>
          </a-select>
        </div>

        <div class="s-v" v-if="formSwitchVal">
          <div v-if="!formFieldsList.length && formSelectDesc !== null">
            暂无数据
          </div>
          <div
            class="v-list"
            v-for="(item, index) in formFieldsList"
            :key="index"
          >
            <div class="title">{{ item.groupName }}</div>
            <ul>
              <li v-for="(it, i) in item.children" :key="i">
                <span @click="insertHtml(it)">{{ it.showName }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="btn-wrapper">
        <div class="wrapper">
          <a-button type="primary" @click="preViewPdf">预览</a-button>
          <a-button type="primary" :disabled="saving" @click="getHtmlContent"
            >保存</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { GeneralFieldsServiceProxy } from "../../general-fields/services/general-fields-proxies";
import { AgreementCategoryServiceProxy } from "../../agreement-category-fields/services/agreement-category-proxies";
import { AgreementTemplateServiceProxy } from "../services/agreement-templete-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import E from "editor4kg";
import { codeRouting } from "@/app/codesmart";
const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E;

export default {
  mixins: [AppComponentBase],
  name: "templete-editor",
  data() {
    return {
      spinning: false,
      editor: null,
      editorData: "",
      htmlData: "",
      generalFieldsService: null,
      agreementTemplateService: null,
      generalFieldsList: [],
      formFieldsList: [],
      expriedSelectList: [
        { desc: "长期有效", value: 0 },
        { desc: "1天", value: 1 },
        { desc: "2天", value: 2 },
        { desc: "3天", value: 3 },
        { desc: "4天", value: 4 },
        { desc: "5天", value: 5 },
        { desc: "6天", value: 6 },
        { desc: "其他", value: -1 },
      ],
      categorySelectList: [],
      expriedSelectVal: null,
      expriedSelectDesc: "请选择签署有效期",
      categorySelectDesc: "请选择协议分类",
      agreementSelectDesc: "请选择协议类型",
      formSelectDesc: null,
      commonService: null,
      switchVal: true,
      formSwitchVal: false,
      submitData: {
        agreementCategoryId: null,
        collectionFormId: null,
        agreementType: null,
        name: "",
        describe: "",
        sort: "",
        signEffective: "",
        content: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入模版名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 40,
            message: "模版名称长度2到40字",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序值",
            trigger: "blur",
          },
          {
            min: 1,
            max: 1000,
            message: "排序值为数字",
            trigger: "blur",
          },
        ],
        agreementCategoryId: [
          {
            required: true,
            message: "请选择协议分类",
            trigger: "change",
            placeholder: "请选择",
          },
        ],
        signEffective: [
          {
            required: true,
            message: "请输入签署有效期",
            trigger: "change",
          },
          {
            min: 1,
            max: 3,
            message: "日期长度最少1天最多365天",
            trigger: "blur",
          },
        ],
        describe: [
          {
            required: false,
            message: "请输入模版描述",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "模版描述长度1到100字",
            trigger: "blur",
          },
        ],
      },
      ids: [],
      formDisabled: false,
      formSelectData: [], // 表单数据列表
      generalFieldsAll: [], // 当前全部通用字段
      formFieldsAll: [], // 当前全部表单字段
      selectPdfTabs: ["default"],
      taskId: "",
      spinning: false,
      fileList: [],
      uploadHeaders: {},
      uploadUrl: "",
      showUploadList: false,
      spTemplateInfo: {
        path: "",
        fields: [],
      },
      checkSuccessField: [],
      checkErrorField: [],
    };
  },
  computed: {},
  created() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);

    this.generalFieldsService = new GeneralFieldsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.agreementTemplateService = new AgreementTemplateServiceProxy(
      this.$apiUrl,
      this.$api
    );
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this._agreementCategoryGetSelectData();
    this.initEditor();
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$route.params.id !== "create") {
          this._agreementTemplateGetEditById(this.$route.params.id);
        }
      }, 500);
    });
  },
  mounted() {},
  methods: {
    async initEditor() {
      const editor = new E(`#myEdit`);
      editor.config.height = 750;
      editor.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "justify",
        "quote",
        "emoticon",
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
        "selectValue",
      ];
      // let generalFields = await this._generalFieldsOfAgreementContentGetList()
      let generalFields = await [];
      editor.create(generalFields);
      this.editor = editor;
    },
    // 根据id获取模版信息
    async _agreementTemplateGetEditById(id) {
      this.spinning = true;
      this.formDisabled = true;
      this.editor.disable();
      try {
        let res = await this.agreementTemplateService.agreementTemplateGetEditById(
          id
        );
        this.submitData = {
          ...res,
          sort: res.sort.toString(),
          signEffective: res.signEffective.toString(),
          agreementType:
            res.agreementType === "NaturalPerson" || res.agreementType === 0
              ? 0
              : 1,
        };
        if (res.content == "") {
          this.spTemplateInfo = {
            absPath: res.pdfTemplateUrl,
            path: AppConsts.uploadBaseUrl + res.pdfTemplateUrl,
            fields: res.analysisPDF,
          };
          this.selectPdfTabs = ["other"];
        }

        this.categorySelectDesc = this.categorySelectList.filter(
          (item) => item.value.toLowerCase() === res.agreementCategoryId
        )[0].text;
        if (res.signEffective < 7) {
          this.expriedSelectDesc = this.expriedSelectList[
            res.signEffective
          ].desc;
          this.expriedSelectVal = this.expriedSelectList[
            res.signEffective
          ].value;
        } else {
          this.expriedSelectDesc = this.expriedSelectList[7].desc;
          this.expriedSelectVal = -1;
        }

        if (res.collectionFormId !== null) {
          this.formSwitchVal = true;
          this.submitData.collectionFormId = res.collectionFormId;
        }

        this.switchVal = res.status === "Enabled" ? true : false;

        this.agreementSelectDesc =
          res.agreementType === "NaturalPerson" || res.agreementType === 0
            ? "自然人"
            : "非自然人";

        this._getFormSelectData(
          res.agreementType,
          this.submitData.generalFieldsOfAgreementContentIds
        );

        this._generalFieldsOfAgreementContentGetList(
          this.submitData.agreementType,
          this.submitData.generalFieldsOfAgreementContentIds
        );

        this.editor.txt.html(res.content);
        // clear focus
        let selection = window.getSelection
          ? window.getSelection()
          : document.getSelection();
        selection.removeAllRanges();

        // this.generalFieldsList.map(item => {
        //   if (this.submitData.content.indexOf(item.key) !== -1) {
        //     if (!this.ids.length) {
        //       this.ids.push({ key: item.key, id: item.id })
        //     } else if (!this.ids.filter(val => val === item.key).length) {
        //       this.ids.push({ key: item.key, id: item.id })
        //     }
        //   }
        // })
        // console.log(this.ids)
        this.editor.enable();
        this.spinning = false;
        this.formDisabled = false;
      } catch (error) {
        this.editor.enable();
        this.spinning = false;
        this.formDisabled = false;
      }
    },
    // 获取协议类型
    async _agreementCategoryGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementCategory/GetSelectData",
          params: null,
        });
        this.categorySelectList = res;
        // this.$nextTick(() => {
        //   if (this.$route.params.id !== "create")
        //     this._agreementTemplateGetEditById(this.$route.params.id);
        // });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 根据协议类型获取通用字段
     * @type 协议类型
     * @curGeneralFieldsOfAgreementContentIds 修改时存入已有的通用字段组
     */
    async _generalFieldsOfAgreementContentGetList(
      type,
      curGeneralFieldsOfAgreementContentIds
    ) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/GeneralFieldsOfAgreementContent/GetList",
          params: { type: type },
        });
        let arr = [];
        res.map((item, index) => {
          item.children.map((it) => {
            it.type = "generalFields";
            it.showName = `${it.showName}${index + 1}`;
            arr.push(it);
          });
        });
        this.generalFieldsList = res;
        // localStorage.generalFieldsAll = JSON.stringify(arr);
        // console.log('gf-arr:', arr)
        // console.log('gf-curGeneralFieldsOfAgreementContentIds:', curGeneralFieldsOfAgreementContentIds)
        if (curGeneralFieldsOfAgreementContentIds) {
          // 获取当前编辑模版已有通用字段后需要生成当前ids
          arr.map((item) => {
            for (
              let j = 0;
              j < curGeneralFieldsOfAgreementContentIds.length;
              j++
            ) {
              if (item.id === curGeneralFieldsOfAgreementContentIds[j]) {
                if (!this.ids.filter((val) => val.key === item.key).length) {
                  this.ids.push({ key: item.key, id: item.id });
                  break;
                }
              }
            }
          });
          // console.log('this.ids:', this.ids)
        }
        this.generalFieldsAll = arr;
        this.generalFnField();
        this.editor.create(this.formFieldsAll.concat(arr));
        this.spinning = false;
        return arr;
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 读取表单Select数据
     * @type 表单类型
     * @curGeneralFieldsOfFormId 修改时存入已有的表单字段组
     */
    async _getFormSelectData(type, curGeneralFieldsOfFormId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url:
            "/api/services/app/GeneralFieldsOfAgreementContentGroup/GetFormSelectData",
          params: { type: type },
        });
        this.formSelectData = res;
        if (this.submitData.collectionFormId !== null && !this.formSelectDesc) {
          this.formSelectDesc = res.filter(
            (item) =>
              item.formId.toLowerCase() ===
              this.submitData.collectionFormId.toLowerCase()
          )[0].value;
          this._getListByFormId(
            this.formSelectDesc,
            curGeneralFieldsOfFormId ? curGeneralFieldsOfFormId : []
          );
        }
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 根据表单id获取采集表单字段
     * @id 表单id
     * @curGeneralFieldsOfFormId 修改时存入已有的表单字段组
     */
    async _getListByFormId(id, curGeneralFieldsOfFormId) {
      this.clearField();
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url:
            "/api/services/app/GeneralFieldsOfAgreementContent/GetListByFormId",
          params: { id: id },
        });
        let arr = [];
        res.map((item) => {
          item.children.map((it) => {
            it.showName = `${it.showName}(表单)`;
            it.type = "formFields";
            arr.push(it);
          });
        });
        this.formFieldsList = res;

        if (curGeneralFieldsOfFormId && curGeneralFieldsOfFormId.length) {
          // 获取当前编辑模版已有通用字段后需要生成当前ids
          arr.map((item) => {
            for (let j = 0; j < curGeneralFieldsOfFormId.length; j++) {
              if (item.id === curGeneralFieldsOfFormId[j]) {
                if (!this.ids.filter((val) => val.key === item.key).length) {
                  this.ids.push({
                    key: item.key,
                    id: item.id,
                    showName: item.showName,
                  });
                  break;
                }
              }
            }
          });
          // console.log('this.ids:', this.ids)
        }
        this.formFieldsAll = arr;
        this.generalFnField();
        this.editor.create(this.generalFieldsAll.concat(arr));
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 保存模版
    _agreementTemplateSave() {
      if (this.selectPdfTabs[0] == "default") {
        this._submitnormalTemplate();
      }
      if (this.selectPdfTabs[0] == "other") {
        this.submitData.content = "";
        this._submitspTemplate();
      }
    },
    _submitnormalTemplate() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.submitData.agreementType === null) {
            this.$message.info("请选择协议类型");
            return;
          }
          if (
            this.submitData.content === null ||
            this.submitData.content === ""
          ) {
            this.$message.info("请输入模版内容");
            return;
          }
          // this.saving = true;
          this.spinning = true;
          let info = {
            ...this.submitData,
            status: this.switchVal ? 1 : 2,
            sort: Number(this.submitData.sort),
            signEffective: Number(this.submitData.signEffective),
          };
          let generalFieldsOfAgreementContentIds = this.ids.map((item) => {
            return item.id;
          });
          // console.log('generalFieldsOfAgreementContentIds: ', generalFieldsOfAgreementContentIds)
          info.generalFieldsOfAgreementContentIds = generalFieldsOfAgreementContentIds;
          if (this.$route.params.id !== "create") {
            info.id = this.$route.params.id;
          }
          console.log(this.formSelectData);
          //原来的formId因为结构的问题，取值是字段分组ID，需要重新处理一下，赋值为真的FromId
          if (info.collectionFormId != null && this.formSelectData.length) {
            let realFormId = this.formSelectData.filter((value, index) => {
              return (
                value.value.toLowerCase() ==
                  info.collectionFormId.toLowerCase() ||
                value.formId.toLowerCase() ==
                  info.collectionFormId.toLowerCase()
              );
            })[0].formId;
            info.collectionFormId = realFormId;
          }
          // console.log({
          //   info: info,
          //   generalFieldsOfAgreementContentIds: generalFieldsOfAgreementContentIds,
          // });
          try {
            let res = await this.agreementTemplateService.agreementTemplateSave(
              {
                info: info,
                generalFieldsOfAgreementContentIds: generalFieldsOfAgreementContentIds,
              }
            );
            this.notify.success(this.l("SavedSuccessfully"));
            this.spinning = false;
            this.saving = false;
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          } catch (error) {
            this.spinning = false;
            this.saving = false;
          }
        } else {
          this.spinning = false;
          this.saving = false;
          console.log("error submit!!");
          return false;
        }
      });
    },

    _submitspTemplate() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.submitData.agreementType === null) {
            this.$message.info("请选择协议类型");
            return;
          }
          if (this.checkSuccessField.length == 0) {
            this.$message.info(
              "上传的特殊模板未找到任何匹配的ACROFORM表单值！"
            );
            return;
          }
          if (this.checkErrorField.length > 0) {
            this.$message.info("上传的特殊模板未通过检查！无法上传");
            return;
          }
          // this.saving = true;
          this.spinning = true;
          let info = {
            ...this.submitData,
            pdfTemplateUrl: this.spTemplateInfo.absPath, //特殊模板的URL地址(相对地址)
            status: this.switchVal ? 1 : 2,
            sort: Number(this.submitData.sort),
            signEffective: Number(this.submitData.signEffective),
          };
          let generalFieldsOfAgreementContentIds = this.checkSuccessField.map(
            (item) => {
              return item.id;
            }
          );
          // console.log('generalFieldsOfAgreementContentIds: ', generalFieldsOfAgreementContentIds)
          info.generalFieldsOfAgreementContentIds = generalFieldsOfAgreementContentIds;
          if (this.$route.params.id !== "create") {
            info.id = this.$route.params.id;
          }
          console.log(this.formSelectData);
          //原来的formId因为结构的问题，取值是字段分组ID，需要重新处理一下，赋值为真的FromId
          if (info.collectionFormId != null && this.formSelectData.length) {
            let realFormId = this.formSelectData.filter((value, index) => {
              return (
                value.value.toLowerCase() ==
                  info.collectionFormId.toLowerCase() ||
                value.formId.toLowerCase() ==
                  info.collectionFormId.toLowerCase()
              );
            })[0].formId;
            info.collectionFormId = realFormId;
          }
          // console.log({
          //   info: info,
          //   generalFieldsOfAgreementContentIds: generalFieldsOfAgreementContentIds,
          // });
          try {
            let res = await this.agreementTemplateService.agreementTemplateSave(
              {
                info: info,
                generalFieldsOfAgreementContentIds: generalFieldsOfAgreementContentIds,
              }
            );
            this.notify.success(this.l("SavedSuccessfully"));
            this.spinning = false;
            this.saving = false;
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          } catch (error) {
            this.spinning = false;
            this.saving = false;
          }
        } else {
          this.spinning = false;
          this.saving = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleFormChange(value) {
      // console.log(value)
      this.submitData.collectionFormId = value;
      this._getListByFormId(value);
      let txt = await this.editor.txt.html();
      this.clearEditorFields(txt, "formFields");
    },
    selectPdfTabsEvent({ item, key, selectedKeys }) {
      this.selectPdfTabs = selectedKeys;
      if (selectedKeys[0] == "default") {
        this.initEditor();
      }
    },
    generalFnField() {
      if (
        this.generalFieldsAll.length == 0 ||
        this.spTemplateInfo.fields.length == 0
      ) {
        this.clearField();
        return;
      }
      let errorArray = [];
      let successArray = [];
      this.spTemplateInfo.fields.forEach((element) => {
        let filterArr = this.generalFieldsAll.filter((value, index) => {
          return value.key == element;
        });
        let filterFormArr = this.formFieldsAll.filter((value, index) => {
          return value.key == element;
        });
        filterFormArr.forEach((ci) => {
          filterArr.push(ci);
        });
        if (filterArr.length == 0) {
          errorArray.push({ key: element, item: "未知" });
        } else {
          successArray.push({
            key: element,
            item: filterArr[0].showName,
            id: filterArr[0].id,
          });
        }
      });

      this.checkSuccessField = successArray;
      this.checkErrorField = errorArray;
    },
    clearField() {
      this.checkSuccessField = [];
      this.checkErrorField = [];
    },
    async handleChange(info) {
      // console.log('info:', info);
      this.clearField();
      const status = info.file.status;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        let message = info.file.response;
        console.log(message);
        //analysisPDF
        this.spinning = true;
        try {
          let res = await this.agreementTemplateService.analysisPDF({
            filePath: AppConsts.uploadBaseUrl + message.data.Path,
          });
          this.spTemplateInfo = {
            absPath: message.data.Path,
            path: AppConsts.uploadBaseUrl + message.data.Path,
            fields: res,
          };
          this.generalFnField();
        } catch (error) {}

        // console.log(result)
        this.spinning = false;

        // console.log(this.dataList);
      } else if (status === "error") {
        let message = info.file.response.error.message;
        this.$message.error(`${info.file.name}文件上传失败.`);
      }
    },
    //限制文件上传大小
    beforeUpload(file) {
      console.log(file);
      var fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isFile = !(fileName != "pdf");

      if (!isFile) {
        this.$message.error("文件格式必须是pdf!");
      }
      const isLt10M = file.size / 1024 / 1024 <= 20;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 20MB！");
      }
      return isFile && isLt10M;
    },

    handleCategoryChange(value) {
      // console.log(`selected ${value}`);
      this.categorySelectDesc = this.categorySelectList.filter(
        (item) => item.value === value
      )[0].text;
      this.submitData.agreementCategoryId = value;
    },
    handleExpriedChange(value) {
      // console.log(`selected ${value}`);
      this.expriedSelectVal = value;
      this.expriedSelectDesc = this.expriedSelectList.filter(
        (item) => item.value === value
      )[0].desc;
      if (value !== -1) {
        this.submitData.signEffective = value;
      } else {
        this.submitData.signEffective = "";
      }
    },
    async handleAgreementTypeChange(value) {
      this.formSelectData = [];
      this.formFieldsList = [];
      this.formSelectDesc = null;

      this.submitData.agreementType = value;
      this.agreementSelectDesc = value === 0 ? "自然人" : "非自然人";
      // this._generalFieldsOfAgreementContentGetList(value === 0 ? 'NaturalPerson' : 'UnnaturalPerson')
      this._generalFieldsOfAgreementContentGetList(value);
      this._getFormSelectData(value);
      // let getJSON = this.editor.txt.getJSON()
      // let _getJSON = JSON.parse(JSON.stringify(getJSON))
      // console.log('getJSON:', getJSON)
      // let setJSON = this.clearEditorFields(_getJSON)
      // console.log('setJSON:', setJSON)
      // // this.editor.txt.html(setJSON);
      // this.editor.txt.setJSON(setJSON)
      let txt = await this.editor.txt.html();
      this.clearEditorFields(txt, "generalFields");
    },
    // clearBr(arr) {
    //   arr.forEach((item, index) => {
    //     if (item.children && item.children.length) {
    //       let _arr1 = item.children
    //       _arr1.forEach((_item1, _index1) => {
    //         if (_item1.tag && _item1.tag === 'br' && (_arr1[_index1 + 1] && _arr1[_index1 + 1].tag && _arr1[_index1 + 1].tag === 'br')) {
    //           _arr1.splice(_index1, 1)
    //         } else if (_item1.children && _item1.children.length) {
    //           this.clearBr(_item1.children)
    //         }
    //       })
    //     }
    //   })
    //   // console.log(arr)
    //   return arr
    // },
    // clearEditorFields(arr) {
    //   arr.forEach((item, index) => {
    //     if (item.children && item.children.length) {
    //       let _arr1 = item.children
    //       _arr1.forEach((_item1, _index1) => {
    //         // console.log('_item1: ', _item1)
    //         if (_item1.tag && _item1.tag === 'u' && _item1.attrs[0].value === 'atwho-inserted') {
    //           // console.log("_item1.tag && _item1.tag === 'u' && _item1.attrs[0].value === 'atwho-inserted'", _item1)
    //           _arr1.splice(_index1, 1)
    //         } else if (_item1.children && _item1.children.length) {
    //           this.clearEditorFields(_item1.children)
    //         }
    //       })
    //     }
    //   })
    //   // console.log(arr)
    //   return arr
    // },
    signAtChange(e) {
      if (e.target.value && Number(e.target.value) > 365) {
        this.submitData.signEffective = 1;
        this.$message.info("最大日期不能超过365天");
      }
      this.$refs.signEffective.onFieldBlur();
    },
    closeE() {
      this.$router.back();
    },
    async getHtmlContent() {
      // console.log(this.editor.txt.html());
      this.saving = true;
      try {
        this.submitData.content = await this.editor.txt.html();
        let arr = this.ids;
        arr.map((item, i) => {
          if (this.submitData.content.indexOf(item.key) === -1) {
            this.ids = this.ids.filter((val) => val.key !== item.key);
          }
        });
        this._agreementTemplateSave();
      } catch (error) {
        this.saving = false;
      }
    },
    async preViewPdf() {
      if (this.selectPdfTabs[0] == "default") {
        this.submitData.content = await this.editor.txt.html();
        let arr = this.ids;
        arr.map((item, i) => {
          if (this.submitData.content.indexOf(item.key) === -1) {
            this.ids = this.ids.filter((val) => val.key !== item.key);
          }
        });

        let generalFieldsOfAgreementContentIds = this.ids.map((item) => {
          return item.id;
        });
        let res = await this.agreementTemplateService.preViewPdf({
          content: this.submitData.content,
          generalFieldsOfAgreementContentIds: generalFieldsOfAgreementContentIds,
        });
        console.log(res);
        window.open(`${AppConsts.uploadBaseUrl}${res}`);
      } else {
        window.open(this.spTemplateInfo.path);
      }
    },
    insertHtml(item) {
      let code = item.key;
      let str = item.showName;
      let type;
      if (!this.ids.length) {
        this.ids.push({ key: code, id: item.id });
      } else if (!this.ids.filter((val) => val.key === code).length) {
        this.ids.push({ key: code, id: item.id });
      }
      if (item.type === "generalFields") {
        type = "gf-inserted";
      } else if (item.type === "formFields") {
        type = "ff-inserted";
      }
      // console.log(this.ids)
      this.editor.cmd.do(
        "insertHTML",
        `
          <u class=${type}><span class="${code}">#${str}#</span></u><span>&nbsp;</span>
        `
      );
    },
    clearEditorFields(str, type) {
      let reg;
      let _str;
      if (type === "generalFields") {
        reg = new RegExp('\\<u class="gf-inserted"(.+?)\\</u>', "g");
        _str = str.replace(reg, "");
        reg = new RegExp('\\<u class="ff-inserted"(.+?)\\</u>', "g");
        _str = _str.replace(reg, "");
        this.ids = [];
      } else if (type === "formFields") {
        reg = new RegExp('\\<u class="ff-inserted"(.+?)\\</u>', "g");
        _str = str.replace(reg, "");

        this.formFieldsList.map((item) => {
          this.ids = this.ids.filter((v) => v.key !== item.id);
        });
      } else {
        reg = new RegExp('\\<u class="atwho-inserted"(.+?)\\</u>', "g");
        _str = str.replace(reg, "");
        this.ids = [];
      }
      // let reg = new RegExp('\\<u class="atwho-inserted"(.+?)\\</u>', "g");
      // let _str = str.replace(reg, "");
      this.editor.txt.html(_str);
    },
  },
  beforeDestroy() {
    document.getElementsByTagName("body")[0].style.overflow = "";
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  components: {},
};
</script>

<style lang="less" scoped>
@import "../../../../styles/common.less";
.e-col-chi span {
  margin-bottom: 5px;
}
.templete-editor {
  position: relative;
  #w100;
  height: 100vh;
  .ee-w {
    position: fixed;
    z-index: 200;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 68px;
    background: @color-white;
    overflow: auto;
    #flex;
    width: 100vw;
    // #w100;
    // height: 100vh;
    // &::before{
    //   display: table;
    //   content: "";
    // }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .e-l {
    min-width: 120px;
    position: relative;
    .btn-w {
      position: absolute;
      left: 50%;
      top: 24px;
      transform: translate3d(-50%, 0, 0);
      padding: 2px 7px 2px 6px;
    }
  }
  .e-c {
    #flex1;
    overflow: auto;
    .e-w {
      padding: 24px 30px;
      min-width: 796px;
      margin: 0 auto;
      .str-l {
        border-top: 1px solid #e1e6eb;
        font-size: 12px;
        color: #80848f;
        padding: 0 5px;
        height: 24px;
        line-height: 22px;
        text-align: right;
        background-color: #fff;
        border-radius: 0 0 4px 4px;
        bottom: 0 !important;
        top: auto !important;
        z-index: 1000 !important;
      }
    }
  }
  .e-r {
    width: 320px;
    border-left: 1px solid #f0f4f8;
    padding: 12px 24px;
    overflow-y: auto;
    .s-n {
      // margin-top: 6px;
      &:first-of-type {
        margin-top: 0;
      }
      .t {
        #fs16;
        // margin: 4px auto;
        #t-color-border(@color-blue);
      }
      .ant-row {
        margin-bottom: 0;
      }
    }
    .f-w {
      #flex;
      // #flex-jcsb;
      // #flex-vc;
      #w100;
      // margin-top: 6px;
      .w:first-of-type {
        width: 50%;
      }
      .s-n {
        // margin-top: 0;
      }
    }
    .s-v {
      // margin: 8px auto;
      // margin-top: 6px;
      .t {
        #fs16;
        #t-color-border(@color-blue);
        border-bottom: 1px solid #f0f4f8;
      }
      .ds {
        margin-bottom: 6px;
        font-size: 12px;
        color: #80848f;
      }

      .v-list {
        .title {
          color: @color-black;
          padding: 4px 0;
          #fs16;
          #lh18;
        }
        ul {
          #flex;
          #flex-hw;
          #flex-jcsb;
          padding-left: 0;
          margin-bottom: 0;
          li {
            list-style: none;
            width: 48%;
            margin-right: 1%;
            margin-bottom: 2%;
            span {
              #tac;
              #dib;
              padding: 3px 5px;
              #w100;
              #h100;
              border: 1px solid @color-blue;
              #border-radius(3px);
              background-color: @color-white;
              color: @color-blue;
              &:hover {
                background-color: @color-blue;
                color: @color-white;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .btn-wrapper {
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0;
    z-index: 20;
    background-color: #ecf5ff;
    .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .atwho-inserted {
    color: @color-blue;
    border-bottom: 1px solid #000;
    padding: 0 8px;
  }
}

div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
}
div::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 72%);
  // border-radius: 3px;
}
div::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-thumb:active {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-track {
  box-shadow: 0 0 0 gray inset;
}
</style>
